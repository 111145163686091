.mediaObject {
  display: inline-block;
  margin: 0 1em 1em 0;
  width: 143px;
  min-height: 143px;
  position: relative;
  vertical-align: top;
  font-size: small;
  &.active:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: var(--primary);
    opacity: 0.5;
  }
  & .thumbnail {
    width: 143px;
    height: 143px;
    position: absolute;
  }
  & .caption {
    text-align: center;
    padding: 0.25em;
    margin-top: 150px;
  }
}
