.quill > div:first-child {
    border-radius: 0.25rem 0.25rem 0 0;
}

.quill > div:first-child + div {
    border-radius: 0 0 0.25rem 0.25rem;
}

.quill .ql-editor p ~ p {
    margin-top: 0;
    margin-bottom: 0;
}

.was-validated .form-control:invalid ~ .quill > div {
    border-color: #e55353;
}

.was-validated .form-control:valid ~ .quill > div {
    border-color: #2eb85c;
}  