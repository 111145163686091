.list {
  transform: translateZ(0);
  position: relative;
  &.dragging .gridItem > * {
    transition: margin 0.20s;
  }
}

.listItem {
  background: white;
  display: inline-flex;
  vertical-align: top;
  margin-bottom: 40px;
  padding: 0 7.5px 0 7.5px;
  position: relative;
  > * {
    margin: 0 0 0 0;
    * {
      pointer-events: none;
    }
  }
  &.draggable {
    cursor: move;
  }
  &:after {
    content: "";
    display: block;
    width: 125px;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #ffcacc;
    opacity: 0;
  }
  &.hoverRight {
    z-index: 1000;
    & > * {
      margin: 0 140px 0 0;
    }
    &:after {
      transition: opacity 0s linear 0.2s;
      opacity: 1;
      right: 7.5px;
    }
  }
  &.hoverLeft {
    z-index: 1000;
    & > * {
      margin: 0 0 0 140px;
    }
    &:after {
      transition: opacity 0s linear 0.2s;
      opacity: 1;
      left: 7.5px;
    }
  }
}
